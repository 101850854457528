import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const LockClosed = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M5.5 10a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v6a3.5 3.5 0 01-3.5 3.5H9A3.5 3.5 0 015.5 16v-6z"
        stroke={color}
      />
      <path
        d="M8.5 9a.5.5 0 001 0h-1zm6 0a.5.5 0 001 0h-1zm-5 0V7h-1v2h1zm5-2v2h1V7h-1zM12 4.5A2.5 2.5 0 0114.5 7h1A3.5 3.5 0 0012 3.5v1zM9.5 7A2.5 2.5 0 0112 4.5v-1A3.5 3.5 0 008.5 7h1z"
        fill={color}
      />
      <path d="M12 13v3" stroke={color} strokeLinecap="round" />
    </svg>
  );
});

LockClosed.displayName = 'LockClosed';

export default LockClosed;
